<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-form @submit.prevent="formSubmit">
                    <b-card title="">

                        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
                          <b-col md=12>

                            <div class="demo-inline-spacing float-right ">
                              
                                <b-button
                                  variant="danger"
                                  class="mt-0 mb-1"
                                  :to="{ name: 'questions'}"
                                >
                                <feather-icon
                                    icon="TargetIcon"
                                    class="mr-25"
                                  />
                                  <span>Important Points</span>
                                </b-badge>
                              </b-button>

                              

                            </div>
                          </b-col>
                        </b-row>

                        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                            <div class="alert-body">
                                {{error_message}}
                            </div>
                        </b-alert>

                        

                        <b-row>

                          <b-col md="12">
                              
                              <h4 class="card-title float-left"> Add Toolbox Meeting</h4>
                              
                          </b-col>
                          
                        </b-row>
                        
                        <b-row>
                            <b-col md="12">
                                <b-row>

                                    <b-col md="4">
                                        <b-form-group label="Project Site" class="required">
                                            <b-form-select v-model="form.site" @change="getShiftData(); siteCrew()">
                                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Conducted By" class="required">
                                            <b-form-input placeholder="3 to 50 characters" v-model="form.conducted_by"/>
                                        </b-form-group>
                                    </b-col>
                                    
                                    <b-col md="4">
                                        <b-form-group label="Date Time" class="required">
                                            <date-picker 
                                                v-model="form.conducted_date" 
                                                format="DD MMM YYYY HH:mm" 
                                                type="datetime"
                                                placeholder="Select"
                                                :disabled-date="disabledDate"
                                                valueType="format"
                                                :clearable=false
                                                lang="en"
                                                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                                            >
                                            </date-picker>
                                        </b-form-group>
                                    </b-col>
                                    
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-card>

                    <b-card>
                        <b-row>
                            <b-col md="5">
                                <b-form-group label="Shift">                
                                    <v-select                    
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="form.shift"
                                        label="shift"
                                        :options="shifts"
                                        placeholder="Select Shift"
                                        multiple
                                        @input="siteChange()"
                                        :close-on-select=false
                                        :clear-on-select=false
                                        class="multiselect_muliple_options"
                                    />
                                        <!-- @option:selected="shift_add"
                                        @option:deselected="shift_remove" -->
                                </b-form-group>
                            </b-col>
                            <b-col md="5">
                                <b-form-group label="Remaining User">                
                                    <v-select
                                        v-model="rem_crew"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="full_name"
                                        :options="selected_remaining_crew"
                                        placeholder="Remaining User"
                                        multiple
                                        :close-on-select=false
                                        :clear-on-select=false
                                         class="multiselect_muliple_options"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" style="padding-top: 5px;">
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" :disabled="rem_crew == []" class = "mt-2" @click="updateCrew()"> Add </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <!-- All Crew -->
                    <b-card v-if="form.crew && form.crew.length > 0">

                        <div class="fmTable table-responsive" style="margin-bottom: 0px !important;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 4%;" class="text-center">#</th>
                                        <th scope="col" style="width: 72%;">Crew Name</th>
                                        <th scope="col" style="width: 20%;" class="text-center">Signature</th>
                                        <th scope="col" style="width: 4%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(cr, index) in form.crew" :key="index">
                                        <td class="text-center"> {{index + 1}} </td>
                                        <td>
                                            <b-form-input placeholder="Crew Name" v-model="cr.full_name" autocomplete="off"/>
                                        </td>
                                        <td class="text-center">
                                            <div v-if="cr.crew_sign.default == 'yes' && cr.crew_sign.image">
                                                <img :src="cr.crew_sign.image" class="rounded" style="background: #ffffff; height: 80px; width: auto">
                                            </div>
                                            <div v-else>
                                                <CrewSignature v-bind:index="index"/>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <b-button variant = "danger" @click="removeCrew(index, cr);">-</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- <b-row class="mb-1 mt-1">
                            <b-col md="1">
                                <h5> # </h5>
                            </b-col>

                            <b-col md="8">
                                <h5> Crew Name </h5>
                            </b-col>

                            <b-col md="2" class="text-center">
                                <h5> Signature </h5>
                            </b-col>

                            <b-col md="1"></b-col>
                        </b-row>
                        <hr>
                        <div v-for="(cr, index) in form.crew" :key="index">
                            <b-row class="mb-0">
                                <b-col md="1" class="mb-1"> {{index + 1}} </b-col>
                                <b-col md="8" class="mb-1"> 
                                    <b-form-input placeholder="Crew Name" v-model="cr.full_name" autocomplete="off"/> <br>
                                </b-col>
                                
                                <b-col md="2" class="text-center">
                                    <div v-if="cr.crew_sign.default == 'yes' && cr.crew_sign.image">
                                        <img :src="cr.crew_sign.image" class="rounded" style="background: #ffffff; height: 80px; width: auto">
                                    </div>
                                    <div v-else>
                                        <CrewSignature v-bind:index="index"/>
                                    </div>
                                </b-col>

                                <b-col md="1" class="text-center">
                                    <b-button variant = "danger" @click="removeCrew(index, cr);">-</b-button>
                                </b-col>
                            </b-row>
                            <hr >
                        </div> -->

                        <b-row>
                            <b-col md="12" class="text-center">
                                <hr>
                                <b-button class="" variant="primary" @click="addCrew">Add More</b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card v-else>
                        <h5 class="text-center">No Crew Found.</h5>
                    </b-card>

                    <!-- important Points -->
                    <b-card v-if="form.site != ''">
                        <div class="fsmTable table-responsive" style="margin-bottom: 0px !important;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 4%;" class="text-center">#</th>
                                        <th scope="col" style="width: 96%;">Important Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(pts, index) in form.important_points" :key="index">
                                        <td class="text-center"> {{index + 1}} </td>
                                        <td>
                                            <b-form-input v-model="pts.question_name"></b-form-input>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- <b-row class="mb-1 mt-1">
                            <b-col md="1">
                                <h5> # </h5>
                            </b-col>

                            <b-col md="11">
                                <h5 class="text-center"> Important Points </h5>
                            </b-col>

                        </b-row>
                        <hr>
                        <div v-for="(pts, index) in form.important_points" :key="index">
                            <b-row class="mb-0">
                                <b-col md="1" class="mb-1"> {{index + 1}} </b-col>
                                <b-col md="11" class="mb-1">
                                    <b-form-input v-model="pts.question_name"></b-form-input>
                                </b-col>
                            </b-row>
                            <hr v-if="(form.important_points.length-1) != index">
                        </div> -->
                    </b-card>

                    <!-- Other Matter -->
                    <b-card v-if="form.site != ''">
                        <b-row>
                            <b-col md="12" class="mb-1" >
                                <b-form-group label="Other Matter">
                                    <b-form-textarea rows="5" v-model="form.other_matter" placeholder="Mention Other Matter..."/>
                                </b-form-group>
                            </b-col> 
                        </b-row>
                    </b-card>
                    
                    <!-- supervisor signature -->
                    <b-card v-if="form.site != ''">

                        <b-row>
                            <!--<b-col cols="5" class="mb-2 mt-2"> </b-col>-->
                            <b-col cols="12" class="mb-2 mt-2 margin_bottom_zero_mobile margin_top_zero_mobile"> 

                                
                                    <b-col cols="12" class="mobile_pdng_lft_rgt_zero mb-2 mt-2 margin_top_zero_mobile">
                                        <center>
                                            <h4 class="mb-0 align_start_mobile"> Signature </h4>
                                        </center>
                                    </b-col>
                                

                                
                                    <b-col cols="12" class="desktop_center align_start_mobile mb-2 mt-0 full_height_width_img_mobile mobile_pdng_lft_rgt_zero"> 
                                        <b-media class="mb-2 mt-2 inline_media_mobile desktop_display_block" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                                            
                                            <template #aside>
                                                <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                                            </template>

                                            <div class="d-flex flex-wrap">
                                                <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                                            </div>

                                        </b-media>

                                        <img :src="supervisorSign.image" width="200px" class="rounded" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">

                                        <center>
                                            <b-button variant="danger" class="mt-1 ml-2 btn_danger_mobile_left_mrgn_zero" v-if="supervisorSign.image != null" @click="removeSign('supervisor')">
                                                <feather-icon icon="XIcon" />
                                            </b-button>
                                        </center>
                                    </b-col>
                                

                            </b-col>
                        </b-row>
                    
                        <b-row>
                            <b-col class="daily_report_sign">

                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class = "mr-1" @click="goBack()">
                                    Cancel
                                </b-button>
                                
                                

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(form.id)" v-if="form.id != null">
                                    Discard Draft
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "primary" class = "mr-1" @click="form.status = 'draft'" :disabled="form.site == ''">
                                    <!-- Save As Draft -->
                                    {{ form.id == null ? 'Save as Draft' : 'Update Draft'}}
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1" @click="form.status = 'completed'">
                                    Submit
                                </b-button>

                                
                            </b-col>
                        </b-row>
                    </b-card>

                </b-form>

            </b-col>
        </b-row>

        <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col md="3">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col md="3">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

    </div>
</template>
<script>
    import {
        BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile, BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';
    import CrewSignature from './CrewSignature.vue';
    import Bus from "../../../event-bus";
    import moment from "moment-timezone";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

    export default {
        components: {
            DatePicker, CrewSignature, BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile, BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,
                draftData:null,
                sites : null,
                shifts:[],
                temp_crew:[],
                rem_crew:[],
                selected_remaining_crew:[],

                form :{
                    id:null,
                    site : ''/*this.$route.params.site_id*/,
                    shift:[],
                    conducted_by : this.$store.getters.currentUser.full_name,
                    crew: [],
                    important_points: [],
                    other_matter: null,
                    status:'completed',
                    conducted_date: moment().tz('Asia/Singapore').format('DD MMM YYYY HH:mm')
                },

                // signature vars
                doc_icon: require('@/assets/images/doc.png'),
                supervisorSign : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                },
                openedSignModel:null,
                tempSign : {
                    image:null,
                    name:'',
                },
                show_default:false,
                having_default:false,
                
            }
        },

        methods: {
            disabledDate(date){
                return date > moment().tz('Asia/Singapore')
            },

            shift_add(e){
                var all_rem_crew = this.selected_remaining_crew;

                var upd_crew = [];

                var shift = e[e.length-1]._id;
                
                for(var j = 0; j < all_rem_crew.length; j++){
                    if(all_rem_crew[j].supervisor_shift.indexOf(shift) < 0){
                        upd_crew.push(all_rem_crew[j]);
                    }

                    if(all_rem_crew[j].supervisor_shift.indexOf(shift) >= 0){
                        this.form.crew.push(all_rem_crew[j])
                        this.form.crew.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0))
                    }
                }

                this.selected_remaining_crew = upd_crew;

                this.rem_crew = [];

                return this.selected_remaining_crew;
            },

            shift_remove(e){
                var new_crew = [];

                for(var ind = 0; ind < this.form.crew.length; ind++){
                    if( !this.form.crew[ind].supervisor_shift.includes(e._id) ){
                        new_crew.push(this.form.crew[ind])
                    } else {
                        this.selected_remaining_crew.push(this.form.crew[ind])
                    }
                }

                this.form.crew = new_crew;
                this.form.crew.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0))

                this.selected_remaining_crew.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0))

                this.rem_crew = [];
            },

            updateCrew(){

                this.form.crew.push(this.rem_crew)
                this.form.crew = [].concat.apply([], this.form.crew); // flatten array 

                this.form.crew.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0))

                var temp = [];

                const getKey = (array,key) => array.map(a => a[key]);

                this.selected_remaining_crew.forEach(src => {
                    if(!getKey(this.rem_crew,'_id').includes(src._id)){
                        temp.push(src)
                    }
                });

                this.selected_remaining_crew = temp;
                this.rem_crew = [];
            },
        
            formSubmit(){
                if (this.form.status == 'completed') {
                    this.decisionAlert('Are you sure want to submit this report ?')
                    .then(result => {
                      if (result.value) {
                        this.submitFunc();            
                      }
                    })
                }else{
                    this.submitFunc();
                }
            },
            submitFunc(){
              return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                        supervisorSign : this.supervisorSign,
                    },
                    api : '/api/add-toolbox-meeting'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        //this.showDismissibleAlert = true;

                        //window.scrollTo(0,0);
                        this.errorAlertCF()
                    } else {
                        this.showDismissibleAlert = false;

                        var data = this.$store.getters.getResults.data;

                        this.successAlert().then((result) => {

                            this.form = {
                                site : ''/*this.$route.params.site_id*/,
                                conducted_by : this.$store.getters.currentUser.full_name,
                                crew: [],
                                important_points: [],
                                other_matter: null,
                                conducted_date: moment().tz('Asia/Singapore').format('YYYY-MM-DD HH:mm')
                            };

                            this.supervisorSign = {
                                image:null,
                                name:'',
                                type: 'supervisor',
                                default:'no'
                            };
                            
                            this.openedSignModel=null;

                            if (this.sites.length == 1) {
                            
                                this.form.site = this.sites[0]._id;                                
                            }


                            this.siteChange();

                            if (data != null) {
                                window.open(data, '_blank');
                            }
                            localStorage.setItem('routeCFCheck','no');
                            Bus.$emit('counter_update');

                            // this.$router.go(-1);
                            this.$router.push({ name:'custom-forms' })
                        });
                    }
                });
            },

            allImportantPoints(){
                return this.$store.dispatch(POST_API, {
                    data:{ site: this.form.site },
                    api: '/api/all-important-point'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data = this.$store.getters.getResults.data;
                        
                        this.form.important_points = data;
                    }
                });
            },
            
            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;

                        if (this.sites.length == 1) {
                            
                            this.form.site = this.sites[0]._id;
                            this.getShiftData()
                            this.siteChange();
                            
                        }

                        return this.sites;
                    }
                });
            },

            siteCrew(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site: this.form.site,
                        role: ['crew', 'supervisor'],
                        except_user_id: [this.$store.getters.currentUser._id],
                    },
                    api: '/api/all-crew-by-role'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        // this.form.crew = [];
                        // this.form.shift = [];
                        this.selected_remaining_crew = [];
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        data.forEach(user => {
                            var tempUser = {};
                            tempUser._id = user._id;
                            tempUser.full_name = user.full_name;
                            tempUser.supervisor_shift = user.supervisor_shift;
                            if(user.default_signature){
                                tempUser.crew_sign = {
                                    default:'yes',
                                    name:'signature.png',
                                    image:user.default_signature,
                                }
                            } else{
                                tempUser.crew_sign = {
                                    default:'no',
                                    name:'',
                                    image:null,
                                };
                            }
                            
                            this.selected_remaining_crew.push(tempUser);
                        });
                    }
                });
            },

            siteCrewDraft(temp_id){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site: this.form.site,
                        user_ids: temp_id
                    },
                    api: '/api/all-crew'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.selected_remaining_crew = [];
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        data.forEach(user => {
                            var tempUser = {};
                            tempUser._id = user._id;
                            tempUser.full_name = user.full_name;
                            tempUser.supervisor_shift = user.supervisor_shift;
                            if(user.default_signature){
                                tempUser.crew_sign = {
                                    default:'yes',
                                    name:'signature.png',
                                    image:user.default_signature,
                                }
                            } else{
                                tempUser.crew_sign = {
                                    default:'no',
                                    name:'',
                                    image:null,
                                };
                            }
                            
                            this.selected_remaining_crew.push(tempUser);
                        });
                    }
                });
            },

            addCrew(){
                this.form.crew.push({
                    full_name: '',
                    supervisor_shift: [],
                    crew_sign: {
                        default:'no',
                        name:'',
                        image:null,
                    }
                })
            },

            removeCrew(index, row) {
                if(row.supervisor_shift.length > 0){
                    this.selected_remaining_crew.push(this.form.crew[index])
                }

                this.form.crew.splice(index, 1);

                var user_shift = [];

                this.form.crew.forEach(el => {
                    user_shift.push(el.supervisor_shift)
                })

                user_shift = [].concat.apply([], user_shift);

                var unique_shift = [ ...new Set(user_shift)]

                var new_shift = [];

                this.form.shift.forEach(el => {
                    if(unique_shift.includes(el._id)){
                        new_shift.push(el)
                    }
                });

                this.form.shift = new_shift;

                this.selected_remaining_crew.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0))
            },
            
            addPoints(){
                this.form.important_points.push({
                    _id: null,
                    question_name: ''
                })
            },

            removePoints(index) {
                if(this.form.important_points.length > 1){
                    this.form.important_points.splice(index, 1);
                }
            },

            supervisorImageUpload(event){
                var input = event.target;
                var files = event.target.files

                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.readAsDataURL(input.files[0]);
                    var image = input.files[0];

                    if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Please upload .png, .jpeg, .jpg images only',
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.$refs.supervisorSInput.value=null;

                    } else if (image.size > 2097152) {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Maximum 2 MB files allowed to be upload.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$refs.supervisorSInput.value=null;
                    } else {
                        reader.onload = (e) => {
                            this.supervisorSign.image = e.target.result;
                            this.supervisorSign.name = image.name;
                            this.supervisorSign.type =  'supervisor';
                            this.supervisorSign.default = 'no';
                        }
                    }
                }
            },
                
            removeSign(sign){
                this.supervisorSign.image = null;
                this.supervisorSign.name = '';
                this.supervisorSign.default = 'no';
            },
            siteChange(){
                this.form.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
                this.getdraftData()
                .then(() => {

                    if(this.draftData && this.draftData.supervisorSign.image){
                        this.supervisorSign = this.draftData.supervisorSign;
                    } else {
                        this.supervisorSign = {
                            image : this.tempSign.image,
                            name : 'signature.png',
                            type :   'supervisor',
                            default:'yes'
                        }
                    }

                    if (this.draftData == null) {
                        
                        this.form.id = null;
                        this.form.conducted_by = this.$store.getters.currentUser.full_name,
                        this.form.conducted_date = moment().tz('Asia/Singapore').format('DD MMM YYYY HH:mm'),
                        this.form.other_matter = null;
                        this.form.status = 'completed';
                        // this.siteCrew();
                        this.allImportantPoints();

                        // this.refreshUsers();
                    }else{
                        this.form.id = this.draftData.id;
                        this.form.conducted_by = this.draftData.conducted_by;
                        this.form.conducted_date = moment(this.draftData.conducted_date).format('DD MMM YYYY HH:mm');
                        this.form.other_matter = this.draftData.other_matter;
                        this.form.status = this.draftData.status;
                        this.form.crew = this.draftData.crew;
                        this.form.important_points = this.draftData.important_points;
                        // this.form.shift = this.draftData.shift;
                        // this.selected_remaining_crew = this.draftData.selected_remaining_crew;

                        var temp_id = [];
                        this.form.crew.forEach(el => {
                            if(el._id && el._id.includes('random') == false){
                                temp_id.push(el._id)
                            }
                        })

                        this.form.crew.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0))

                        this.supervisorSign = this.draftData.supervisorSign;
                        this.siteCrewDraft(temp_id);
                    } 


                });
                
            },

            /* refreshUsers(){
                this.siteCrew().then(() => {

                    var all_rem_crew = this.selected_remaining_crew;
    
                    var upd_crew = [];
    
                    this.form.crew = [];      
                    
                    if(this.form.shift.length > 0){
                        for(var i = 0; i < this.form.shift.length; i++){
                            var shift = this.form.shift[i]._id;
                            for(var j = 0; j < all_rem_crew.length; j++){
                                if(all_rem_crew[j].supervisor_shift.includes(shift)){
                                    this.form.crew.push(all_rem_crew[j]);
                                } else {
                                    upd_crew.push(all_rem_crew[j]);
                                }
                            }
                        }
                        this.form.crew.sort((a,b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : ((b.full_name.toLowerCase() > a.full_name.toLowerCase()) ? -1 : 0));
                    } else {
                        this.siteCrew()
                    }
                    
                    if(this.form.crew.length >= all_rem_crew.length){
                        this.selected_remaining_crew = [];
                    } else {
                        this.selected_remaining_crew = upd_crew;
                    }
    
                    this.rem_crew = [];
                })
            }, */
            getdraftData(){
                
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.form.site != '' ? this.form.site : null,
                        shift:this.form.shift,
                    },
                    api: '/api/draft-toolbox-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        this.draftData = data;

                        this.rem_crew = [];
                        this.selected_remaining_crew = this.$store.getters.getResults.remaining_crew;
                        this.form.crew = this.$store.getters.getResults.selected_crew;
                        
                        return this.draftData;
                    }
                });
            },
            discardItem(id){
                var msg = 'Are you sure want to discard this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'discard'
                            },
                            api : "/api/change-status-toolbox-data",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                /*this.successAlert()
                                .then(() => {
                                    this.form.site = ''; 
                                    this.supervisorSign = {
                                        image:null,
                                        name:'',
                                        type: 'supervisor',
                                        default:'no'
                                    };

                                    this.siteChange();
                                });*/
                                localStorage.setItem('routeCFCheck','no');
                                this.successAlert()
                                .then(() => {
                                    
                                    // this.$router.go(-1);
                                    this.$router.push({ name:'custom-forms' })
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },
            useDefault(){

              return this.$store.dispatch(POST_API, {
                   data:{
                     id:this.$store.getters.currentUser._id
                   },
                   api: '/api/get-default-signature'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        if (data == null || data.default_signature == null) {
                          
                          this.having_default = false;
                          
                        }else{

                          this.tempSign = {
                            image : data.default_signature,
                            name : 'siganture.png',
                          }

                            this.supervisorSign = {
                                image:data.default_signature,
                                name:'signature.png',
                                type: 'supervisor',
                                default:'yes'
                            };

                          this.having_default = true;
                        }
                    }
                });
            },
            uploadSign(){
              
                this.$refs['supervisorSInput'].click();
              
                this.$refs['signatureModel'].hide();

            },
            openSignature(type){
              if (this.openedSignModel == null) {

                  if (this.having_default == true) {
                      this.show_default = true;
                  }else{
                    this.show_default = false;
                  }

                  this.$refs['signatureModel'].show();
                  this.openedSignModel = type;
              }
            },
            hideSignature(){
              this.openedSignModel = null;
            },
            clearSignature(){
              this.$refs.signaturePad.clearSignature();
            },
            saveSignature(){
              const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
              
              if (isEmpty) {

                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Signature is required',
                  showConfirmButton: false,
                  timer: 1500
                })

              }else{


                this.supervisorSign.image = data;
                this.supervisorSign.name = 'siganture.png';
                this.supervisorSign.type = 'supervisor';
                this.supervisorSign.default = 'no';

                this.$refs['signatureModel'].hide();
              }
            },
            saveDefault(){
              
              
                this.supervisorSign = {
                  image : this.tempSign.image,
                  name : 'signature.png',
                  type :   'supervisor',
                  default:'yes'
                }
                this.$refs['signatureModel'].hide();
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:null,
                text:'Add Toolbox Meeting',
                active:true
              }];
              return item;
            },
            goBack(){

                if (this.form.site != '') {
                    var msg = 'Do you want to Go back without saving your report?';

                    this.decisionAlert(msg)
                    .then(result => {
                      if (result.value) {
                        
                        // this.$router.go(-1);
                        this.$router.push({ name:'custom-forms' })

                      }
                    })
                }else{
                    // this.$router.go(-1);    
                    this.$router.push({ name:'custom-forms' })                
                }
                
            }, 

            getShiftData(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.form.site != '' ? this.form.site : null,
                    },
                    api: '/api/site-shifts'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        this.temp_crew = [];
                        this.rem_crew = [];
                        // this.selected_remaining_crew = [];
                        this.form.shift = [];
                        this.form.crew = [];
                        // this.selected_remaining_crew = []

                        this.form.id = null,
                        this.form.conducted_by = this.$store.getters.currentUser.full_name,
                        this.form.important_points = [],
                        this.form.other_matter = null,
                        this.form.status = 'completed',
                        this.form.conducted_date = moment().tz('Asia/Singapore').format('DD MMM YYYY HH:mm')
                        
                        this.shifts = data;
                        return this.shifts;
                    }
                });
            },

        },
        mounted(){
            this.useDefault();
            this.allSites();
            this.siteChange();

            // to add crew sign
            Bus.$on('update-image', (data) => {
                this.form.crew[data.index].crew_sign = data.data;
            })

            // to delete crew sign
            Bus.$on('remove-image', (data) => {
                this.form.crew[data].crew_sign = {
                    default:'no',
                    name:'',
                    image:null,
                };
            })
        }
    }
</script>
